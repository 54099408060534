import React, { useEffect, useState } from "react";
import "./Style/profiledetail.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Feedback } from "./Feedback";
import SideBar from "../Components/SideBar/SideBar";
import TextLinkExample from "../Components/NavBar/NavBar";
import { useContext } from "react";
import { SidebarContext } from "../../context/SidebarContext";
import { FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { usePortfolioListQuery } from "../../redux/API/portfolioApi";
import LogoutCard from "../Components/Card/LogoutCard";
import Footer from "../Components/Footer/Footer";
import { formatIndianNumber } from "../../Constant/utils";
import moment from 'moment';
import axios from "axios";
import { baseUrl } from "../../Constant/Matcher";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ProfileDetail() {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);
  const clientCode = localStorage.getItem("clientCode");
  const { data: portfolioList, isLoading } = usePortfolioListQuery(clientCode);
  const [responseMap, setResponseMap] = useState({});
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.validateEmail?._id;
  const sumOfAmounts = portfolioList?.data?.reduce(
    (sum, item) => sum + item?.avg * item.qty,
    0
  );

  const totalCurrentValue = portfolioList?.data?.reduce((acc, result) => {
    const currentprice = result?.stocklist?.currentprice;
    return acc + (currentprice ? currentprice * result.qty : 0);
  }, 0);
  const handleNavigate = (e, clientcode, scripname, scrip) => {
    e.preventDefault();
    navigate(`${clientcode}/${scripname}/${scrip}`);
  };

  const postDateForItem = (values, dates, payload, index) => {
    axios.post(`${baseUrl}client/cagr`, [ values, dates, payload])
    .then((res) => {
      const { clientcagr, stockId } = res?.data;
      setResponseMap(prevMap => ({
        ...prevMap,
        [stockId]: clientcagr 
      }));
    })
    .catch((error) => {
      // console.error(`Error for index ${index}:`, error);
    });
};
  

const processPortfolioData = () => {
  portfolioList?.data?.forEach((portfolioItem, index) => {
    const stockId = portfolioItem?._id?.stockid; 
    const clientId = userId; 
    const values = portfolioItem?.access_targets_out?.map((item) =>
      item.SellVal ? -item.SellVal : item.buyval
    );
    const dates = portfolioItem?.access_targets_out?.map((item) =>
      item.trasactiondate.slice(0, 10)
    );
    
    const lastValue = -(portfolioItem?.stocklist?.currentprice * portfolioItem?.qty);
    const today = moment().format('YYYY-MM-DD');
    
    values?.push(lastValue);
    dates?.push(today);

    const payload = {
      stockId: stockId,
      clientId: clientId,
    };

    postDateForItem(values, dates, payload, index);
  });
};



useEffect(() => {
  if (portfolioList?.data) {
    processPortfolioData();
  }
}, [portfolioList?.data]);


  return (
    <>
      <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className="ProfileDetail">
            <div
              className="UpperBtn my-4  mx-5"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <button className="download-btn mt-2 me-2">
                Download Investment Report
              </button>
            </div>
            <div className="row mx-5">
              <div className="col-md-6">
                <div className="card ProfileDetail-card  row-1">
                  <div className="mx-4">
                    <h5 className="card-title">Actual Investment</h5>
                    <h2 className="Values">
                      ₹ {formatIndianNumber(sumOfAmounts)}
                    </h2>
                  </div>
                  <img
                    src={require("../Components/photos/Animation - 1697460328274.gif")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="card ProfileDetail-card row-1">
                  <div className="mx-4">
                    <h5 className="card-title">Current Value</h5>
                    <h2 className="Values">
                      ₹ {formatIndianNumber(totalCurrentValue)}
                    </h2>
                  </div>
                  <img
                    src={require("../Components/photos/Animation - 1697459940103.gif")}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div
              className="portfolio-detail-table my-5 col-11 flex-column align-items-lg-start  "
              style={{ margin: "auto" }}
            >
              <p className="mt-2">
                {portfolioList?.data?.length} Record(s) Found
              </p>
              <TableContainer className="" component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>S.No.</StyledTableCell>
                      <StyledTableCell>Stock</StyledTableCell>
                      <StyledTableCell>QTY</StyledTableCell>
                      <StyledTableCell>Invested Value&nbsp;(₹)</StyledTableCell>
                      <StyledTableCell>Avg. Price</StyledTableCell>
                      <StyledTableCell> Current Price&nbsp;(₹)</StyledTableCell>
                      <StyledTableCell> Current Value&nbsp;(₹)</StyledTableCell>
                      <StyledTableCell>Profilt</StyledTableCell>
                      <StyledTableCell>Profilt %</StyledTableCell>
                      <StyledTableCell>CAGR</StyledTableCell>
                      <StyledTableCell>Transaction</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {isLoading ? (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell className="text-center" colSpan={9}>
                          Loading...
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {portfolioList &&
                        [...portfolioList.data]
                          .sort((a, b) =>
                            a._id.scripname.localeCompare(b._id.scripname)
                          )
                          ?.map((result, index) => {
                            const LTP = result?.stocklist?.currentprice;
                            const stockId = result?._id?.stockid;
                            const clientCagr = responseMap[stockId] != null ? responseMap[stockId].toFixed(2) : "";
                            return (
                              <StyledTableRow key={result?._id?.stockid}>
                                <StyledTableCell>
                                  <strong>{index + 1}</strong>
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {result?._id.scripname}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {result?.qty ? result?.qty : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {result?.val ? formatIndianNumber( result?.avg * result?.qty) : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {(result?.avg).toFixed(2)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {result?.stocklist?.currentprice}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {formatIndianNumber(LTP * result?.qty)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {( result?.stocklist?.currentprice *  result?.qty - result?.val).toFixed(2)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {((result?.stocklist?.currentprice * result?.qty - result?.val) / (result?.avg * result?.qty)
                                  ).toFixed(2)}%
                                </StyledTableCell>
                               
                                <StyledTableCell>
                                {clientCagr}
                                </StyledTableCell>
                             
                                <StyledTableCell className="view-btn">
                                  <Link
                                    onClick={(e) =>
                                      handleNavigate(
                                        e,
                                        result?._id?.clientcode,
                                        result?._id?.scripcode,
                                        result?._id.scripname,
                                        result?.qty
                                      )
                                    }
                                  >
                                    <FaEye />
                                  </Link>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </div>
          <Footer />
        </div>
        <Feedback />
      </div>
      {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  );
}
