import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import img2 from "../images/DSC_1496-768x512.jpg";
import { useGalleryQuery } from "../../redux/API/galleryApi";
import { gallerImg, imgUrl } from "../constant/constant";
import GallerySkeleton from "../components/partial/GallerySkeleton";
import Navbar from "../components/partial/Navbar";
import Footer from "../components/partial/Footer";
const Gallery = () => {

const { data, isLoading } = useGalleryQuery()  


  return (
    <>
    <Navbar/>
      <div className="py-5 contact-header">
        <div className="  ps-5 text-white  ">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link> <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                  Gallery
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <h1>Gallery</h1>
          </div>
        </div>
      </div>
      <div className="container my-5">
        {
          isLoading ? (
            <div className="row gy-5">
            <GallerySkeleton/>
            </div>
          )
          : (

        
        <div className="row gy-5">
          
          {
            data?.data?.map((i) => ( 
          <div className="col-lg-4 col-md-6" key={i?._id}>
            <Link to={`/gallery/${i?.images?.folderid}`}>
              <div className="card rounded-3 position-relative folder-view w-100 h-100">
                <div className="main-image rounded-3 z-3 shadow">
                <img
                  className="w-100 h-100 rounded-3"
                  src={`${imgUrl}${gallerImg}${i?.images?.galleryimage}`}
                  alt="Main"
                />
                </div>
                <div className="main-image rounded-3 position-absolute w-100 z-2   start-0 shadow  "  style={{ top: "-3%" }}>
                <img
                  src={img2}
                  className="w-100 h-100 rounded-3"
                  alt="Image1"
                 
                />
                </div>
                <div className="gallery-title position-absolute start-0  bottom-0  w-100 py-1 rounded-3 ">
                  <h5 className=" text-center  w-100 text-white">
                   {i?.name}
                  </h5>
                </div>
              </div>
            </Link>
          </div>
            ))
          }
        </div>
          )
        }
      </div>
      <Footer/>
    </>
  );
};

export default Gallery;
