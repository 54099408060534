import React, { useEffect, useState } from "react";
import "./Style/transactionupload.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Feedback } from "./Feedback";
import SideBar from "../Components/SideBar/SideBar";
import TextLinkExample from "../Components/NavBar/NavBar";
import { useContext } from "react";
import { FaEye } from "react-icons/fa";
import { SidebarContext } from "../../context/SidebarContext";
import LogoutCard from "../Components/Card/LogoutCard";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import { useTransectionUploadQuery } from "../../redux/API/transectionupload";
import { formatDate } from "../../Constant/utils";
import { useProfileInfoQuery } from "../../redux/API/portfolioApi";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TransactionUpload() {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.validateEmail?._id;
  // const [transectionList, setTransectionList] = useState([])
  const { data: getProfile } = useProfileInfoQuery();
  const bucket = getProfile?.user?.clientbucketing;
  const { data: transectionList, isLoading } = useTransectionUploadQuery({
    bucket: bucket,
    userId: userId,
  });
  const navigate = useNavigate();
  // const [file, setFile] = useState()
  // const [uploadedFileURL, setUploadedFileURL] = useState(null)

  function handleChange(event) {
    // setFile(event.target.files[0])
  }

  function handleSubmit(event) {
    // event.preventDefault()
    // const url = 'http://localhost:3000/transactionupload';
    // const formData = new FormData();
    // formData.append('file', file);
    // formData.append('fileName', file.name);
    // const config = {
    //   headers: {
    //     'content-type': 'multipart/form-data',
    //   },
    // };
    // axios.post(url, formData, config).then((response) => {
    //   setUploadedFileURL(response.data.fileUrl);
    // });
  }

  useEffect(() => {
    if (transectionList?.data?.type === "new") {
      setShowUpload(false);
    } else {
      setShowUpload(true);
    }
  }, [transectionList?.data?.type]);

  const handleNavigate = (e, userid, adviseid) => {
    e.preventDefault();
    navigate(
      `/client/transactionupload/${userid}/${adviseid}`
    );
  };

  return (
    <>
      <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className="TransactionUpload px-2 min-vh-100 ">
            <div className="TransactionUploadHead col-11  d-flex m-auto ps-2">
              <h4>
                {" "}
                <strong> Advise History</strong>
              </h4>
              <div className="transection-img">
                <img
                  src={require("../Components/photos/Animation - 1697546611765.gif")}
                  alt="Transaction-SUploadImage"
                />
              </div>
            </div>
            <div className="TransactionUploadAdvised my-4 col-11 mx-auto ">
              <div className="row">
                <div
                  className={`col-lg-8 col-md-12 col-sm-12 my-1 ${
                    showUpload ? "col-lg-8" : "col-lg-10 m-auto"
                  }`}
                >
                  <div className="portfolio-detail-table  my-2">
                    <TableContainer
                      className={
                        isSiderbaropen
                          ? "detail-table w-100"
                          : "detail-table-change w-100"
                      }
                      component={Paper}
                    >
                      <Table
                        sx={{ minWidth: showUpload ? 600 : 800 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>S.No.</StyledTableCell>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell>Advice Id</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        {isLoading ? (
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell></StyledTableCell>
                              <StyledTableCell></StyledTableCell>

                              <StyledTableCell>Loading...</StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        ) : (
                          <TableBody>
                            {transectionList &&
                               (transectionList?.prevdata || transectionList?.data)
                                ?.map(
                                  (transaction, index) =>
                                    // transaction?.items?.slice(0,1).map((result) =>
                                    {
                                      return (
                                        <StyledTableRow key={transaction?._id}>
                                          <StyledTableCell>
                                            {index + 1}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            {formatDate(transaction?.createdAt)}
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            {transaction?.adviseid}
                                          </StyledTableCell>
                                          <StyledTableCell className="view-btn">
                                            <Link
                                              onClick={(e) =>
                                                handleNavigate(
                                                  e,
                                                  transaction?.userid,
                                                  transaction?.adviseid
                                                )
                                              }
                                            >
                                              <FaEye />
                                            </Link>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      );
                                    }
                                )
                                // )
                            }
                          </TableBody>
                        )}
                        {!isLoading && (transectionList?.prevdata?.length <= 0) && (
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell>No Record found</StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </div>
                </div>
                {showUpload && (
                  <div className="col-md-12 col-sm-12 my-1 col-lg-4   p-2">
                    <h5>Upload your own Transaction</h5>
                    <div>
                      <select name="" id="" className="w-100 p-2 my-1">
                        <option value="">Select Stock</option>
                        <option value="">Select Stock</option>
                        <option value="">Select Stock</option>
                      </select>
                      <select name="" id="" className="w-100 p-2 my-1">
                        <option value="">Select Action</option>
                        <option value="">Buy</option>
                        <option value="">Sell</option>
                      </select>
                      <label htmlFor="" className="w-100 mt-1">
                        Enter Quantity
                        <input type="text" className="w-100" />
                      </label>
                      <label htmlFor="" className="w-100 mt-1">
                        Enter Price
                        <input type="text" className="w-100 mt-1" />
                      </label>
                      <button className=" border-0 w-100 py-1 mt-2">
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <img src={require("../photos/images/Desktop - 8.png")} alt=''/> */}
          </div>
          <Footer />
        </div>
        <Feedback />
      </div>
      {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  );
}
