import React, { useEffect, useState } from 'react'
import './Style/referus.css'
import { FloatingLabel, Form } from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Feedback } from './Feedback';
import SideBar from '../Components/SideBar/SideBar';
import TextLinkExample from '../Components/NavBar/NavBar';
import { useContext } from 'react';
import { SidebarContext } from '../../context/SidebarContext';
import LogoutCard from '../Components/Card/LogoutCard';
import Footer from '../Components/Footer/Footer';
import { Link } from 'react-router-dom';
import { useProfileInfoQuery } from '../../redux/API/portfolioApi';
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
const ReferUs = () => {
  const { isSiderbaropen } = useContext(SidebarContext) 
  const [showLogout, setShowLogout] = useState(false)
  const [copied, setCopied] = useState(false);
  const [profileData, setProfileData] = useState("");
  const getProfile = useProfileInfoQuery();
  const linkToCopy = 'http://brightermind.in/registration';
  
  useEffect(() => {
    if (getProfile.isSuccess && !getProfile.isError && !getProfile.isLoading) {
      setProfileData(getProfile?.data?.user);
    } else {
      console.log("Something went wrong");
    }
  }, [getProfile]);

  const handleCopy = () => {
    const senderUsername = encodeURIComponent(profileData?.userid?.name); 
    const userId = profileData?._id
    const referId = btoa(userId)
  const linkWithReferral = `${linkToCopy}?referral=${senderUsername}&refid=${referId}`;
  
  navigator.clipboard.writeText(linkWithReferral)
    .then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied("")
      }, 3000);
    })
    .catch((error) => console.log(error));
  }

  const senderUsername = encodeURIComponent(profileData?.userid?.name); 
  const userId = profileData?._id
  const referId = btoa(userId)
 
  const shareOnWhatsApp = () => {
    const encodedText = encodeURIComponent(linkToCopy);
    const url = `https://wa.me/?text=${encodedText}?refid=${referId}%26referral=${senderUsername}`;
    window.location.href = (url);
  };

  const shareOnFacebook = () => {
    const encodedText = encodeURIComponent(linkToCopy);
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedText}?refid=${referId}%26referral=${senderUsername}`;
    window.open(facebookUrl, '_blank');
  };

  const shareViaEmail = () => {
    const encodedText = encodeURIComponent(`Check out this link: ${linkToCopy}`);
    const emailUrl = `mailto:?subject=Check out this link&body=${encodedText}?refid=${referId}%26referral=${senderUsername}`;
    window.location.href = emailUrl;
  };

  const shareOnTelegram = () => {
    const encodedText = encodeURIComponent(linkToCopy);
    const telegramUrl = `https://t.me/share/url?url=${encodedText}?refid=${referId}%26referral=${senderUsername}`;
    window.open(telegramUrl, '_blank');
  };

  return (
    <>
    <div className="App">
    <SideBar />
    <div className={!isSiderbaropen ? 'pages' : 'pages-change'}>
    <TextLinkExample
   setShowLogout={setShowLogout}
    />
    <div className=' ReferUs'>
      <div className='referus1'>
        <div className='referushead mx-4 my-4 py-2' >
          <h2>Refer Us</h2>
          <i className="fa-solid fa-user-group fa-2xl"></i>
        </div>
        <div className='container'>
          <div className="row">
            <div className="col-12">
        <div className='container2 d-flex justify-content-around flex-row flex-wrap w-100'>
            <div className='ReferUsIcon w-50' style={{ textAlign: "center" }}>
              <div className='ReferUsIcons'>
               <Link onClick={shareOnWhatsApp}> <i className="fa-brands fa-square-whatsapp fa-2xl" style={{ color: "#8fe13d" }}></i></Link>
               <Link onClick={shareOnFacebook}> <i className="fa-brands fa-facebook fa-2xl" style={{ color: "#1f55b2" }}></i></Link>
               <Link onClick={shareViaEmail}> <i className="fa-solid fa-envelope fa-2xl" style={{ color: "#ec4646" }}></i></Link>
               <Link onClick={shareOnTelegram}> <i className="fa-brands fa-telegram fa-2xl" style={{ color: "#6294ea" }}></i></Link>
              </div>
              <span>Share On Social Media</span>
            </div>
            <div className='link my-5 w-50' style={{ textAlign: "cente" }}>
              <div className='copylink' style={{ textAlign: "center", border: "1px solid" }}>{linkToCopy}</div>
              <CopyToClipboard text="https://brighterMind.in">
                <button type="submit" className="btn my-2" onClick={handleCopy}> <i className="fa-solid fa-copy"></i> Copy to Clipboard</button>
              </CopyToClipboard>
              {copied && <p className='text-success'><i className="fa-solid fa-check-to-slot"></i> Link copied!</p>}
            </div>
          </div>
          </div>
          <div className="col-lg-6">
          <div className='container1 ms-3'>
            <div className='row'>
              <div className='col'>
                <span><i className="fa-solid fa-user"></i> Name</span>
                <FloatingLabel controlId="floatingInput" label="Name" className="mb-3" >
                  <Form.Control type="name" placeholder="Enter your Name" />
                </FloatingLabel>
              </div>
              <div className='col'>
                <span><i className="fa-solid fa-phone"></i> Phone</span>
                <FloatingLabel controlId="floatingInput" label="Phone" className="mb-3" >
                  <Form.Control type="number" placeholder="+91-----" />
                </FloatingLabel>
              </div>
            </div>
            <span><i className="fa-solid fa-envelope"></i> Email</span>
            <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3" >
              <Form.Control type="email" placeholder="name@example.com" />
            </FloatingLabel>
            <span><i className="fa-solid fa-comment-dots"></i> Message</span>
            <FloatingLabel controlId="floatingTextarea2" label="Message" className="mb-3">
              <Form.Control
                as="textarea"
                placeholder="Leave a comment here"
                style={{ height: '100px' }}
              />
            </FloatingLabel>
            <button type="submit" className="btn my-2"> <i className="fa-solid fa-paper-plane"></i> Send</button>
          </div>
          </div>
          <div className="col-lg-6">
          <MDBTable hover striped>
                  <MDBTableHead dark>
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Date</th>
                      <th scope="col">ReferId</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    
                        <tr>
                          <td>1</td>
                          <td>John</td>
                          <td>22-08-2024</td>
                          <td>3737JSJW523DJ</td>
                        </tr>
                     
                  </MDBTableBody>
                  <MDBTableBody>
                    
                        <tr>
                          <td>1</td>
                          <td>John</td>
                          <td>22-08-2024</td>
                          <td>3737JSJW523DJ</td>
                        </tr>
                     
                  </MDBTableBody>
                  <MDBTableBody>
                    
                        <tr>
                          <td>1</td>
                          <td>John</td>
                          <td>22-08-2024</td>
                          <td>3737JSJW523DJ</td>
                        </tr>
                     
                  </MDBTableBody>
                </MDBTable>
          </div>
          </div>
        </div>

      </div>
    </div>
    <Footer/>
    </div>
      <Feedback/>
      </div>
      {
        showLogout && (
          <LogoutCard
          setShowLogout={setShowLogout}
          />
        )
      }
      </>
  )
}

export default ReferUs