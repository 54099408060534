import React, { useContext, useEffect, useRef, useState } from "react";
import TextLinkExample from "../Components/NavBar/NavBar";
import { Feedback } from "./Feedback";
import LogoutCard from "../Components/Card/LogoutCard";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Footer from "../Components/Footer/Footer";
import { MdDataSaverOn } from "react-icons/md";
import toast, { Toaster } from 'react-hot-toast';
import {
  useTransectionAdviceQuery,
  useTransectionUploadQuery,
} from "../../redux/API/transectionupload";
import { useParams } from "react-router-dom";
import { formatDate } from "../../Constant/utils";
import { useUploadAdviseMutation } from "../../redux/API/profileuploadApi";
import SideBar from "../Components/SideBar/SideBar";
import { SidebarContext } from "../../context/SidebarContext";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AdviceDetails = () => {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [transectionList, setTransectionList] = useState([]);
  const [inputVal, setInputVal] = useState([]);
  const { userid, adviseid } = useParams();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.validateEmail?._id;
  const [stockList, setStockList] = useState([]);
  const [postData, setPostData] = useState(null);
  const getStockList = useTransectionUploadQuery(userId);
  const {data: transectionList,  isLoading: loading} = useTransectionAdviceQuery({
    userid: userid,
    adviseid: adviseid,
  });
  
  const RefgetTransectionList = useRef(
    useTransectionAdviceQuery({
      userid: userid,
      adviseid: adviseid,
    })
  );

  const [postAdviseData, { isLoading }] = useUploadAdviseMutation();
  useEffect(() => {
    
    if (
      getStockList?.isSuccess &&
      !getStockList?.isError &&
      !getStockList?.isLoading
    ) {
      setStockList(getStockList?.data?.stocklist);
    } else {
      // console.log("Something went wrong");
    }
  }, [ getStockList]);


  const handleChange = (e, index) => {
    const { name, value } = e.target;

    setInputVal((prevInputVal) => {
      const newInputVal = { ...prevInputVal };

      if (!newInputVal[index]) {
        newInputVal[index] = {};
      }

      if (name.startsWith("actualQty")) {
        newInputVal[index][name] = value;
      } else if (name.startsWith("actualPrice")) {
        newInputVal[index][name] = value;
      }

      return newInputVal;
    });
  };

  const handleOpenModal =  (e, userid, adviseid, _id, index) => {
    e.preventDefault();
   setShowModal(true);
   setPostData({
    userid: userid,
    adviseid: adviseid,
    actualqty: Number(inputVal[index]?.[`actualQty_${index}`]),
    actualprice: Number(inputVal[index]?.[`actualPrice_${index}`]),
    stockid: _id,
  });
    
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = (e, _id, index) => {
    e.preventDefault();
    if (!postData || !postData.actualqty || !postData.actualprice) {
    toast.error("Please provide Qty and Price")
      return;
    }
    postAdviseData(postData)
      .then(() => {
        setInputVal((prevInputVal) => {
          const newInputVal = { ...prevInputVal };
          newInputVal[index] = {
            actualQty: "",
            actualPrice: "",
          };
          return newInputVal;
        });
      setShowModal(false)
        RefgetTransectionList.current.refetch();
      })
      .catch((error) => error);
  };

  return (
    <>
      <div className="App">
        <Toaster
        position="top-right"
        />
        <SideBar/>
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className="ProfileDetail h-100 ">
            <div className="TransactionUploadHead col-11  d-flex ps-2 m-auto ">
              <h4>
                {" "}
                <strong> Advise Details</strong>
              </h4>
              <div className="transection-img">
                <img
                  src={require("../Components/photos/Animation - 1697546611765.gif")}
                  alt="Transaction-SUploadImage"
                />
              </div>
            </div>
           
              <div
                className="portfolio-detail-table my-5 col-11 "
                style={{ margin: "auto" }}
              >
                <form action="" method="post" className="w-100">
                  <TableContainer className="" component={Paper}>
                    <Table sx={{ minWidth: 600 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>S.No.</StyledTableCell>
                          <StyledTableCell>Date</StyledTableCell>
                          <StyledTableCell>Stock Name</StyledTableCell>
                          <StyledTableCell>Buy / Sale</StyledTableCell>
                          <StyledTableCell>Adviced QTY</StyledTableCell>
                          <StyledTableCell>Adviced Price</StyledTableCell>
                          <StyledTableCell>Actual QTY</StyledTableCell>
                          <StyledTableCell>Actual Price</StyledTableCell>
                          <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {loading ? (
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell className='text-center' colSpan={9}>Loading...</StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        ) : (
                      <TableBody>
                        {transectionList &&
                          transectionList?.data?.map((result, index) => {
                            return (
                              <StyledTableRow>
                                <StyledTableCell>{index + 1}</StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {formatDate(result?.createdAt)}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {result?.stockid?.name}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {result?.stocktype}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {result?.finalqty ? result?.finalqty : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {result?.ltp
                                    ? result?.ltp
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell className="table-input">
                                  {!result?.actualqty ? (
                                    <input
                                      className={
                                        "w-75"
                                      }
                                      type="number"
                                      name={`actualQty_${index}`}
                                      id={`actualQty_${index}`}
                                      value={inputVal[index]?.actualQty}
                                      onChange={(e) =>
                                        handleChange(e, index, result)
                                      }
                                    />
                                  ) : (
                                    `${result?.actualqty}`
                                  )}
                                </StyledTableCell>
                                <StyledTableCell className="table-input">
                                  {!result?.actualprice ? (
                                    <input
                                      className={
                                         "w-75"
                                      }
                                      type="number"
                                      name={`actualPrice_${index}`}
                                      id={`actualPrice_${index}`}
                                      value={
                                        inputVal[index]?.[
                                          `actualPrice_${index}`
                                        ]
                                      }
                                      onChange={(e) => handleChange(e, index)}
                                    />
                                  ) : (
                                    `${(result?.actualprice).toFixed(2)}`
                                  )}
                                </StyledTableCell>
                                <StyledTableCell className="view-btn">
                                  {!result?.actualqty && !result?.actualqty ? (
                                    <button
                                      className="table-btn"
                                      onClick={(e) =>
                                        handleOpenModal(
                                          e,
                                          result?.userid,
                                          result?.adviseid,
                                          result?.stockid?._id,
                                          index
                                        )
                                      }
                                    >
                                      {isLoading ? " Submit..." : "Submit"}
                                    </button>
                                  ) : (
                                    "Already Done"
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                      </TableBody>
                        )
                      }
                      {(!loading && transectionList?.data?.length <= 0) && (
                         <TableBody>
                        
                         <StyledTableCell className='text-center' colSpan={9}>No Record found</StyledTableCell>
                        
                       </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </form>
              </div>
           
          </div>
          <Footer />
        </div>
        <Feedback />
      </div>
     {showModal && ( 
<div className="modal-wrapper">
   
<div className="logout-confirm">
    <div className="body">
        <span><MdDataSaverOn /></span>
        <p>Are you sure, you want to Submit?</p>
    </div>
    <div className="bottom-buttons">
        <button className='no'
        onClick={handleCloseModal}
        >No</button>
        <button className='yes' 
      onClick={ handleSubmit}
        >Yes</button>
    </div>
</div>
   
</div>
     )}
      {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  );
};

export default AdviceDetails;
