import React from 'react'
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineWatchLater } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { useAllBlogQuery } from '../../redux/API/blogApi';
import { formatDate, truncate } from '../constant/utils';
import { blogImg, imgUrl } from '../constant/constant';
import Skeletoncard from '../components/partial/Skeletoncard';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import img1 from '../images/placeholder.jpg'
import Navbar from '../components/partial/Navbar';
import Footer from '../components/partial/Footer';
const Blog = () => {

const {data, isLoading} = useAllBlogQuery()  


  return (
    <>
    <Navbar/>
     <div className="py-5 contact-header">
        <div className="  ps-5 text-white  ">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link> <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                Blogs
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <h1>Blogs</h1>
          </div>
        </div>
      </div>
    <div className="container my-5">
      <div className="row">
        <div className="col-lg-9">
          {
            isLoading ? (
              <Skeletoncard/>
            )
            : 
            (   
          <div className="row gy-3">
            {
              data && data?.data?.map((i) => (  
        <div className="col-lg-4 col-md-6" key={i?._id}>
            <div className="card  ">
             <Link to={i?._id} className=" position-relative latest-update">
             {/* <img src={`${imgUrl}${blogImg}${i?.image}`} className="card-img-top object-fit-cover " alt="..." height={200}   /> */}
             <LazyLoadImage
            className='w-100'
            src={`${imgUrl}${blogImg}${i?.image}`}
            placeholderSrc={img1}
            alt={""}
            height={200}
          />
             <div className="overlay-shadow position-absolute  w-100  h-100  start-0  top-0  align-items-center  justify-content-center  text-white  fs-2">
             <FaPlus />
             </div>
             </Link>
              <div className="card-body py-3 blog-card-body ">
                <div className="border-line mb-3"></div>
                <h5 className="card-title mb-3">
                 {truncate(i?.title, 5)}
                </h5>
               
                <span><MdOutlineWatchLater />{formatDate(i?.createdAt)}</span>
              </div>
            </div>
          </div>
              
              ))
            }
          </div>
            )
          }
        </div>
        <div className="col-lg-3">
          <div className='search-box d-flex justify-content-between '>
            <input type="text" className=' bg-transparent border-0 w-100 py-2 ps-3' placeholder="Search..." />
            <button className=' border-0  contact-header text-white  px-3 py-2 '><FaSearch /></button>
          </div>
          <div className="blog-category mt-5">
            <h4 className=' fw-normal heading mb-4'>Categories</h4>
            <div>
                <div className='category-box p-3 ps-4 mb-1 position-relative '>
                  <Link><h6 className="mb-0">Stock Analysis</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Equity Investment</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Infographics</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Inflation</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Power of Compounding</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Risks in Equity Market</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                 <Link> <h6>Time Value of Money</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Uncategorized</h6></Link>
                </div>
                <div className='category-box p-3 ps-4 mb-1 position-relative'>
                  <Link><h6 className="mb-0">Value Investing</h6></Link>
                </div>
            </div>
          </div>
          <div className="latest-category mt-5">
            <h4 className=' fw-normal heading mb-4'>Latest Posts</h4>
            <div className='mb-4'>
                <p className='m-0 fw-semibold text-start'><Link>The Role of Patience in Value Investing</Link></p>
                <span className='sm-text'><MdOutlineWatchLater /> 2022-12-20</span>
            </div>
            <div className='mb-4'>
                <p className='m-0 fw-semibold text-start'><Link>The Role of Patience in Value Investing</Link></p>
                <span className='sm-text'><MdOutlineWatchLater /> 2022-12-20</span>
            </div>
            <div className='mb-4'>
                <p className='m-0 fw-semibold text-start'><Link>The Role of Patience in Value Investing</Link></p>
                <span className='sm-text'><MdOutlineWatchLater /> 2022-12-20</span>
            </div>
            <div className='mb-4'>
                <p className='m-0 fw-semibold text-start'><Link>The Role of Patience in Value Investing</Link></p>
                <span className='sm-text'><MdOutlineWatchLater /> 2022-12-20</span>
            </div>
            <div className='mb-4'>
                <p className='m-0 fw-semibold text-start'><Link>The Role of Patience in Value Investing</Link></p>
                <span className='sm-text'><MdOutlineWatchLater /> 2022-12-20</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default Blog