import React, { useState } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import "./Style/billsinvoice.css";
import { Feedback } from "./Feedback";
import SideBar from "../Components/SideBar/SideBar";
import TextLinkExample from "../Components/NavBar/NavBar";
import { useContext } from "react";
import { SidebarContext } from "../../context/SidebarContext";
import LogoutCard from "../Components/Card/LogoutCard";
import Footer from "../Components/Footer/Footer";
import { useBillInvoiceQuery } from "../../redux/API/billApi";
import { pdfUrl } from "../../Constant/Matcher";
import { formatDate } from "../../Constant/utils";

const BillsInvoice = () => {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);
  const clientCode = localStorage.getItem("clientCode");
  const { data } = useBillInvoiceQuery(clientCode);
  let dueDate = 45;

  const pendingBillsAmount = data?.billingdetail?.reduce((acc, result) => {
    const bills = result?.pendingamount;
    return acc + (bills ? bills : 0);
  }, 0);

  const countPartiallyPaid = data?.billingdetail?.filter(
    (item) => item.pendingamount
  ).length;

  return (
    <>
      <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className=" BillsInvoice  min-vh-100 ">
            <div className="BillsInvoiceHeader py-2 mx-4 my-4">
              <div className="card">
                <div className="Pending">
                  <span className="pendingTitle mx-2">Pending Bills:</span>
                  <span className="pendingNumber mx-2">
                    {countPartiallyPaid}
                  </span>
                </div>
                <div className="Pending">
                  <span className="pendingTitle mx-2">
                    Pending Bill Amount:
                  </span>
                  <span className="pendingNumber mx-2">
                    {pendingBillsAmount}
                  </span>
                </div>
              </div>
              <div className="button">
                <button type="submit" className="btn my-2">
                  <i class="fa-solid fa-clock-rotate-left fa-spin fa-spin-reverse mx-2"></i>
                  History
                </button>
              </div>
            </div>
            <div className="BillsInvoiceTable mx-4 overflow-y-hidden ">
              <MDBTable
                hover
                striped
                style={{
                  backgroundColor: "RGB(255 255 255)",
                  fontSize: "12px",
                }}
              >
                <MDBTableHead
                  style={{ backgroundColor: "#5A4344", color: "white" }}
                >
                  <tr>
                    <th scope="col">Invoice Number</th>
                    <th scope="col">Bills Amount</th>
                    <th scope="col">Creation Date</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Period</th>
                    <th scope="col">Pending Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Remark</th>
                    <th scope="col">Download</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {data &&
                    data?.billingdetail?.map((i) => (
                      <tr key={i?._id}>
                        <td>{i?.invoiceid}</td>
                        <td>
                          {(i?.billingamount - i?.discount) * 0.18 +
                            (i?.billingamount - i?.discount)}
                        </td>
                        <td>{formatDate(i?.billingdate)}</td>
                        <td>
                          {(() => {
                            const billingDate = new Date(i?.billingdate);
                            billingDate.setDate(
                              billingDate.getDate() + dueDate
                            );

                            const formattedDate = `${String(
                              billingDate.getDate()
                            ).padStart(2, "0")}-${String(
                              billingDate.getMonth() + 1
                            ).padStart(2, "0")}-${billingDate.getFullYear()}`;

                            return formattedDate;
                          })()}
                        </td>
                        <td>
                          {(() => {
                            const [month, day, year] = i?.billingdate
                              .split("/")
                              .map(Number);
                            const billingDate = new Date(year, month - 1, day);

                            let result = "";

                            if (i?.billingfrequency === 2) {
                              // Billing frequency is 2, which means 6 months
                              const startMonth = billingDate.toLocaleString(
                                "default",
                                { month: "short" }
                              );
                              const endDate = new Date(
                                billingDate.setMonth(billingDate.getMonth() + 6)
                              );
                              const endMonth = endDate.toLocaleString(
                                "default",
                                { month: "short" }
                              );
                              result = `${startMonth} ${year} - ${endMonth} ${endDate.getFullYear()}`;
                            } else if (i?.billingfrequency === 1) {
                              // Billing frequency is 1, which means 1 year
                              const endYear = year + 1;
                              result = `${year}-${endYear}`;
                            }

                            return result;
                          })()}
                        </td>

                        <td>
                          {i?.pendingamount !== undefined &&
                          i?.pendingamount !== null
                            ? i.pendingamount
                            : (i?.billingamount - i?.discount) * 0.18 +
                              (i?.billingamount - i?.discount)}
                        </td>
                        <td>{i?.paidstatus === "0" ? "" : i?.paidstatus}</td>
                        <td>{i?.remark ? i?.remark : "NA"}</td>
                        <td className="DownloadIcon">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`${pdfUrl}${i?.billpdf}`}
                          >
                            <span>Invoice</span>
                            <i className="fa-solid fa-file-arrow-down fa-lg mx-4"></i>{" "}
                          </a>
                        </td>
                      </tr>
                    ))}
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
          <Footer />
        </div>
        <Feedback />
      </div>
      {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  );
};

export default BillsInvoice;
