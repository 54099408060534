import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import img1 from '../../images/apar-graph.webp'
import img2 from '../../images/avanti-feeds.webp'
import img3 from '../../images/gael.webp'
import img4 from '../../images/jsl-01-1-660c042575f9f.webp'
import img5 from '../../images/powermech.webp'
const Multibagger = () => {
const [index, setIndex] = useState(1)

const toggleIndex = (ind) => {
  setIndex(ind)
}

  return (
    <>
    <div style={{backgroundColor: "#c1bfbf"}} className="pt-2 pb-4">
      <div className="container mt-3 winner-section position-relative">
        <h2 className="heading text-center ">Our Multibagger</h2>
        <div className="row gy-3 mt-3">
          <div className="col-lg-3">
            <div className="bagger-left-panel bg-white rounded-end-3 pe-3">
              <Link className={`bagger_card  d-flex  align-items-center ${index === 1 ? "active-bg " : ""}`} onClick={() => toggleIndex(1)}>
                <h6 className='ms-4 mb-0 position-relative z-2'>Apar Industries Ltd</h6>
              </Link>
              <Link className={`bagger_card  d-flex  align-items-center ${index === 2 ? "active-bg " : ""}`} onClick={() => toggleIndex(2)}>
                <h6 className='ms-4 mb-0 position-relative z-2'>Avanti Feeds Limited</h6>
              </Link>
              <Link className={`bagger_card  d-flex  align-items-center ${index === 3 ? "active-bg " : ""}`} onClick={() => toggleIndex(3)}>
                <h6 className='ms-4 mb-0 position-relative z-2'>GAEL</h6>
              </Link>
              <Link className={`bagger_card  d-flex  align-items-center ${index === 4 ? "active-bg " : ""}`} onClick={() => toggleIndex(4)}>
                <h6 className='ms-4 mb-0 position-relative z-2'>JSL</h6>
              </Link>
              <Link className={`bagger_card  d-flex  align-items-center ${index === 5 ? "active-bg " : ""}`} onClick={() => toggleIndex(5)}>
                <h6 className='ms-4 mb-0 position-relative z-2'>PowerMech</h6>
              </Link>
            </div>
          </div>
          <div className="col-lg-9 ">
            <div className={`${index === 1 ? "active": ""} bagger-img rounded-2  `}>
              <img src={img1} alt="Graph" className='w-100 h-100 object-fit-cover rounded-2' />
            </div>
            <div className={`${index === 2 ? "active": ""} bagger-img rounded-2`}> <img src={img2} alt="Graph" className='w-100 h-100 object-fit-cover rounded-2' /></div>
            <div className={`${index === 3 ? "active": ""} bagger-img rounded-2`}> <img src={img3} alt="Graph" className='w-100 h-100 object-fit-cover rounded-2' /></div>
            <div className={`${index === 4 ? "active": ""} bagger-img rounded-2`}> <img src={img4} alt="Graph" className='w-100 h-100 object-fit-cover rounded-2' /></div>
            <div className={`${index === 5 ? "active": ""} bagger-img rounded-2`}> <img src={img5} alt="Graph" className='w-100 h-100 object-fit-cover rounded-2' /></div>
          </div>
        </div>
        </div>
        </div>
    </>
  )
}

export default Multibagger