import React from "react";
import logo from "../../images/logo_w.png";
import { TiSocialLinkedin } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti";
import { TiSocialFacebook } from "react-icons/ti";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import { usePagesQuery } from "../../../redux/API/pageApi";
import '../../style/style.css'
const Footer = () => {
const {data} = usePagesQuery()


  return (
    <>
      <footer className="h-auto">
        <div className="container py-4 text-white ">
          <div className="row">
            <div className="col-lg-3">
                <img src={logo} alt="Logo" width={160} />
                <p className="mb-1">SEBI Reg. No. INA100016363</p>
                <p className="mb-0">
                  Mon–Sat: 9:00 AM–5:00 PM <br />
                  Sunday: Closed
                </p>
                <p className="m-0"><a href="mailto:support@brightermind.in">support@brightermind.in</a></p>
                <p>+91-8800308006</p>
            </div>
            <div className="col-lg-9">
              <div className="row gy-4">
                <div className="col-lg-4">
                  <h5 className=" fw-normal ">KNOWLEDGE CENTER</h5>
                  <hr className="my-1"/>
                  <p className="mb-1"><Link className="text-white" to="/blog">Blogs</Link></p>
                  <p className="mb-1"><Link className="text-white" to="/gallery">Library</Link></p>
                  <p className="mb-1"><Link className="text-white" to="/video">Videos</Link></p>
                </div>
                <div className="col-lg-4">
                  <h5 className=" fw-normal ">COMPLIANCE</h5>
                  <hr className="my-1"/>
                  {
                   data && data?.data?.map((i) => (
                     <p className="mb-1" key={i?._id}><Link className="text-white" to={`/${i?.slug}/${i?._id}`}>{i?.pagename}</Link></p>
                   ))
                  }
                {/* <p className="mb-1"><Link className="text-white" to='/site-setting'>Site-Setting</Link></p> */}
                <p className="mb-1">Cyber Security</p>
                <p className="mb-1">KYC Form</p>
                <p className="mb-1">Company Brochure</p>
                  
                </div>
                <div className="col-lg-4">
                  <h5 className=" fw-normal ">Address</h5>
                  <hr className="my-1"/>
                <p >
                  REGISTERED OFFICE: <br />
                  53-B, U.G.Floor, Vijay Block, Laxmi Nagar, <br />
                  New Delhi - 110092
                </p>
                <p>
                  CORPORATE OFFICE: <br />
                  305, Tower 4, Assotech Business Cresterra, Sector-135,
                  Noida–201304
                </p>
                </div>

              </div>
            </div>
            <hr className="my-3" />
            <div className="col-12">
              <div className="d-flex justify-content-between flex-wrap  ">
                <p className="m-0">
                  &#169; 2024 BrightMind.in All rights reserved.
                </p>
                <div className="d-flex mt-3 mt-lg-0  mt-md-0 mt-xl-0 ">
                  <Link
                    to="https://www.facebook.com/brightermindequity"
                    target="_blank"
                    className="mx-2 bg-white  fs-5 social-icon d-flex  justify-content-center  align-items-center "
                  >
                    <TiSocialFacebook />
                  </Link>
                  <Link
                    to="https://twitter.com/BrighterEquity"
                    target="_blank"
                    className="mx-2 bg-white  fs-5 social-icon d-flex  justify-content-center  align-items-center "
                  >
                    <TiSocialTwitter />
                  </Link>
                  <Link
                    to="https://www.instagram.com/brightermindequityadvisor/"
                    target="_blank"
                    className="mx-2 bg-white  fs-5 social-icon d-flex  justify-content-center  align-items-center "
                  >
                    <FaInstagram />
                  </Link>
                  <Link
                    to="https://www.youtube.com/channel/UCe2eNLgqp8ex1LujCqLa5Qg"
                    target="_blank"
                    className="mx-2 bg-white  fs-5 social-icon d-flex  justify-content-center  align-items-center "
                  >
                    <FaYoutube />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/brighter-mind-equity-advisor/"
                    target="_blank"
                    className="mx-2 bg-white  fs-5 social-icon d-flex  justify-content-center  align-items-center "
                  >
                    <TiSocialLinkedin />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
