import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Chart as ChartJS, ArcElement, Tooltip, Legend,registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import Navbar from "../components/partial/Navbar";
import Footer from "../components/partial/Footer";

ChartJS.register(ArcElement, Tooltip, Legend, ...registerables);


const CompoundCalculator = () => {
  return (
    <>
    <Navbar/>
      <div className="py-5 contact-header">
        <div className="  ps-5 text-white  ">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link> <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                  Power of Compounding Calculator
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <h1>Power of Compounding Calculator</h1>
          </div>
        </div>
      </div>
      <div className="container my-5 ">
        <div className="row">
          <div className="col-12 mb-5">
            <div className="row">
                <div className="col-lg-4 text-center">
                    <div className="mb-5">
                        <label htmlFor="" className=" fw-semibold">Amount you want to invest</label>
                        <div className="mt-2">
                            <button className="btn btn-primary px-3">-</button>
                            <span className=" border-top border-bottom   border-1 border-black py-1 px-5 ">0</span>
                            <button className="btn btn-primary px-3">+</button>
                        </div>
                    </div>
                    <div className="mb-5">
                        <label htmlFor="" className=" fw-semibold">Investment Time Spant</label>
                        <div className="mt-2">
                            <input type="range" name="" id="" className="w-50"/> <br />
                            <span>31 Years</span>
                        </div>
                        </div>
                        <div className="mb-5">
                        <label htmlFor="" className=" fw-semibold">Estimated Rate of Return</label>
                        <div className="mt-2">
                            <button className="btn btn-primary px-3">-</button>
                            <span className=" border-top border-bottom   border-1 border-black py-1 px-5 ">0</span>
                            <button className="btn btn-primary px-3">+</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 text-center">
                    <h1 className="heading  ">TOTAL RETURN :</h1>
                    
                    <h3 className=" fw-medium ">After 30 years your investment of Rs. 100,000 <br />
                    will grow to <strong> ₹2.37 Crores</strong>
                    </h3>
                   
                    <Bar
                    data={{
                        labels: ['2024', '2027', '2030', '2033', '2036', '2039', '2042', '2045', '2048', '2053'],
                        datasets: [
                          {
                            id: 1,
                            label: '',
                            data: [5000000, 7000000, 10000000, 10000000, 15000000, 20000000, 21000000, 22000000, 23000000,25000000, 27000000, 29000000,30000000],
                          },
                         
                        ],
                      }}
                    />
                </div>
            </div>
          </div>
          <div className="col-12 faq-container">
            <h2 className="py-1 text-center">Frequently Asked Questions</h2>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Q1. What is the formula for calculating compound interest?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      The formula for compound interest is A = P(1 + r/n)^nt,
                      where A is an accrued amount, P is the principal amount, r
                      is the interest rate, n is the number of times interest is
                      compounded per unit of time, and t is the number of the
                      time period.
                    </p>
                    <p>
                      For example, suppose you have invested Rs. 1,00,000 for 20
                      years. You are earning 10% per annum on your investments.
                      With the above formula, you can calculate how much you
                      will have at the end of 20 years.
                    </p>
                    <p>
                      A= 1,00,000(1+10%/1)^20 = Rs. <strong>6,72,749</strong>
                      <p>
                        It means the total interest you have earned is 5,72,749
                        over the period of 20 years.
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Q2. How to use the power of compounding calculator?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      If you want to calculate how much money you will have
                      after particular time, you can use the above power of
                      compounding calculator. Below are the steps to use the
                      compounding calculator.
                    </p>

                    <div>
                      <div>Step 1: Enter the amount you want to invest </div>
                      <div>
                        Step 2: Select the time frame of your investment. You
                        can move the slider to select the investment time.
                      </div>
                      <div>
                        {" "}
                        Step 3: Enter your expected return on investment.
                      </div>
                    </div>
                    <p>
                      After following these steps, you will see the graph on the
                      right-hand side. This will give you a clear idea of how
                      much amount you would have at the end of the investment
                      term. The power of compounding is a powerful tool that
                      helps you understand how much money you need to invest to
                      achieve your desired amount at the end of the investment.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Q3. What is the advantage of the compound interest
                    calculator?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      {" "}
                      The main advantage of the power of compounding calculator
                      is:
                    </p>
                    <div>
                      <div>
                        a. It is easy to use and shows you the investment growth
                        within seconds.
                      </div>
                      <div>
                        b. This calculator helps you set your financial goal and
                        plan for achieving it.
                      </div>
                      <div>
                        c. You can experiment with the interest rate and time
                        for an investment to see how your investment value
                        changes and easily compare the returns.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Q4. What is daily, monthly, and yearly compounding?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                  <p>
                  The amount which you will receive at the end of the investment term depends on the frequency of compounding. While investing, you should always look for how often the interest is compounded. There are various options available where the compound interest is accrued daily, monthly, and yearly basis. These are nothing but the frequency of compounding.
                  </p>

                <p>
                Let’s understand this with a simple example. Consider you invested Rs. 1,00,000 for 5 years. You have three options where the rate of return is 10%, which is accrued on a daily, monthly and annual basis. So, you will end up with Rs.1,64,000 if the compounding is done on monthly basis, Rs.1,62,889 if it is done semi-annually, and Rs.1,61,051 if it is done on annual basis.
                </p>
                  </div>
                </div>  
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Q5. What is the difference between simple interest and
                    compound interest?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                   <p>
                   There are two ways in which the interest is earned on the investment – simple interest and compound interest. The key difference between simple interest and compound interest is as follows:
                   </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default CompoundCalculator;
