import React from 'react'
import Banner from '../components/Home/Banner'
import About from '../components/Home/About'
import Winner from '../components/Home/Winner'
import Whyus from '../components/Home/Whyus'
import Offer from '../components/Home/Offer'
import MidBanner from '../components/Home/MidBanner'
import Blog from '../components/Home/Blog'
import LastBanner from '../components/Home/LastBanner'
import Testimonial from '../components/Home/Testimonial'
import Strategy from '../components/Home/Strategy'
import  '../style/style.css'
import Navbar from '../components/partial/Navbar'
import Footer from '../components/partial/Footer'
import Multibagger from '../components/Home/Multibagger'

const Home = () => {

  



  return (
    <>
    <Navbar/>
    <Banner/>
    <About/>
    <Winner/>
    <Multibagger/>
    <Whyus/>
    <Offer/>
    <Strategy/>
    <Testimonial/>
    <MidBanner/>
    <Blog/>
    <LastBanner/>
    <Footer/>
    </>
  )
}

export default Home