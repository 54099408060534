import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit'
import React from 'react'
import '../Style/portfolioreview.css'
import { useContext } from 'react';
import { SidebarContext } from '../../../context/SidebarContext';
import SideBar from '../../Components/SideBar/SideBar';
import TextLinkExample from '../../Components/NavBar/NavBar';
import { Feedback } from '../Feedback';
import { usePortfoliodataQuery, useProfileInfoQuery } from '../../../redux/API/portfolioApi';
import { formatDate } from '../../../Constant/utils';

const PortfolioReview = () => {
  const { isSiderbaropen } = useContext(SidebarContext)
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.validateEmail?._id;
  const {data: profile} = useProfileInfoQuery()
 const {data} = usePortfoliodataQuery(userId)
 
 const duration = profile?.user?.reviewduration


  return (
    <div className="App">
    <SideBar />
    <div className={!isSiderbaropen ? 'pages' : 'pages-change'}>
    <TextLinkExample/>
    <div className=' PortfolioReview'>
      <div className='PortfolioReviewHeader mx-4 my-4 py-2 px-2'>
        <h4>Portfolio Review</h4>
      </div>
      <div className='PortfolioReviewTable mx-4 my-4'> 
      <MDBTable hover striped style={{backgroundColor:"RGB(255 255 255)"}}>
        <MDBTableHead style={{backgroundColor:"#5A4344",color:"white"}}>
          <tr>
            <th scope='col'>Last Review Date</th>
            <th scope='col'>Next Review Date</th>
            <th scope='col'>Done By</th>
            <th scope='col'>Remarks</th>
            
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {
            data && data?.review?.map((result) => {
              const reviewDate = new Date(result?.reviewdonedate);
    reviewDate.setMonth(reviewDate.getMonth() + duration);
    
    const reviewFormat = reviewDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });

              return(
          <tr >
            <td>{formatDate(result?.reviewdonedate)}</td>
            <td>{formatDate(reviewFormat)}</td>
            <td>{result?.reviewdoneBy}</td>
            <td>{result?.reviewremark}</td>
          </tr>
              
            )})
          }
        </MDBTableBody>
      </MDBTable>
      </div>
    </div>
    </div>
      <Feedback/>
      </div>
  )
}

export default PortfolioReview