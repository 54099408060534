import React from "react";
import Navbar from "../components/partial/Navbar";
import Footer from "../components/partial/Footer";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import img1 from '../images/Rajeev.jpg'
import img2 from '../images/Manoj.jpg'
import img3 from '../images/Shashi-400x400-1.jpg'
import img4 from '../images/Sanket-400x400-1.jpg'
import img5 from '../images/madhu-400x400-1.jpg'
import { TiSocialLinkedin } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti";
const OurTeam = () => {
  return (
    <>
      <Navbar />
      <div className="py-5 contact-header">
        <div className="  ps-5 text-white  ">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link> <MdKeyboardArrowRight />
                </li>
                <li className="breadcrumb-item text-white " aria-current="page">
                  Our Team
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <h1>Our Team</h1>
          </div>
        </div>
      </div>
      <section className="mt-5 our-team">
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-4">
                    <div className="team-img-box position-relative">
                    <img src={img1} alt="CA Rajeev Ranjan" className="w-100 h-100 object-fit-cover"/>
                    <div className="hover-content d-flex justify-content-around align-items-end pb-3">
                    <a href="https://x.com/CARajeev_BM" className="text-white"> <TiSocialTwitter  className="fs-4"/></a>
                    <a href="https://www.linkedin.com/in/ca-rajeev-ranjan-640a9527" className="text-white"><TiSocialLinkedin  className="fs-4"/></a>
                   </div>
                    </div>
                </div>
                <div className="col-8">
                    <Link to="/staff/ca-rajeev-ranjan"><h5 className="mb-0">CA Rajeev Ranjan</h5></Link>
                    <p className=" fw-semibold">Founder & CMD</p>
                    <p className="mb-0 text-start">
                    CA Mr. Rajeev Ranjan Founder & CMD Rajeev Ranjan, CA, is Founder and CMD of the Brighter Mind. Prior to this in his role as Chartered Accountant in practice he contributed...
                    </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
             
              <div className="row">
                <div className="col-4">
                   <div className="team-img-box  position-relative">
                   <img src={img2} alt="Mr. Manoj Bandre" className="w-100 h-100 object-fit-cover" />
                   <div className="hover-content d-flex justify-content-around align-items-end pb-3">
                    <a href="https://x.com/manoj_bandre" className="text-white"> <TiSocialTwitter  className="fs-4"/></a>
                    <a href="https://www.linkedin.com/in/manoj-bandre-b-eng-a738bab0/" className="text-white"><TiSocialLinkedin  className="fs-4"/></a>
                   </div>
                   </div>
                </div>
                <div className="col-8">
                    <Link to="/staff/mr-manoj-bandre/"><h5 className="mb-0">Mr. Manoj Bandre</h5></Link>
                    <p className=" fw-semibold">Founder & CEO</p>
                    <p className="mb-0 text-start">
                    Manoj Bandre B.Tech, PGDFA is the founder; CEO of Brighter Mind Equity Advisor Pvt. Ltd. Manoj has previously worked with BLB Ltd. in the Proprietary Fund Management...
                    </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
            
              <div className="row">
                <div className="col-4">
               <div className="team-img-box  position-relative">
               <img src={img3} alt="Mr. Shashi Kant" className="w-100 h-100 object-fit-cover" />
               <div className="hover-content d-flex justify-content-around align-items-end pb-3">
                    <a href="https://x.com/shashitheturtle" className="text-white"> <TiSocialTwitter  className="fs-4"/></a>
                    <a href="https://www.linkedin.com/in/shashi-kant-297a4521/" className="text-white"><TiSocialLinkedin  className="fs-4"/></a>
                   </div>
               </div>
                </div>
                <div className="col-8">
                    <Link to="/staff/mr-shashi-kant"><h5 className="mb-0">Mr. Shashi Kant</h5></Link>
                    <p className=" fw-semibold">Principal Officer & Head of Research</p>
                    <p className="mb-0 text-start">Shashi Kant has overall experience of around 9 years in Investment Management and Equity Research. Prior to joining Brighter Mind Equity Advisors, he has worked with...</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-4">
               <div className="team-img-box  position-relative">
               <img src={img4} alt="Mr. Sanket Borgaonkar" className="w-100 h-100 object-fit-cover"/>
               <div className="hover-content d-flex justify-content-around align-items-end pb-3">
                    <a href="https://x.com/here_is_sankey" className="text-white"> <TiSocialTwitter  className="fs-4"/></a>
                    <a href="https://www.linkedin.com/in/sanket-borgaonkar-a10a25a5/" className="text-white"><TiSocialLinkedin  className="fs-4"/></a>
                   </div>
               </div>
                </div>
                <div className="col-8">
                    <Link to="/staff/mr-sanket-borgaonkar"><h5 className="mb-0">Mr. Sanket Borgaonkar</h5></Link>
                    <p className=" fw-semibold">Chief Strategy Officer</p>
                    <p className="mb-0 text-start">
                    Sanket Borgaonkar is a Mechanical Engineer and holds Post Graduate Diploma in Wealth Management. He has more than 5 years of experience in the field of Financial Planning,...
                    </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-4">
               <div className="team-img-box  position-relative">
               <img src={img5} alt="Mrs. Madhu Diwakar" className="w-100 h-100 object-fit-cover"/>
               <div className="hover-content d-flex justify-content-around align-items-end pb-3">

                    <a href="https://www.linkedin.com/in/madhu-diwaker-99a1b0216/" className="text-white"><TiSocialLinkedin  className="fs-4"/></a>
                   </div>
               </div>
                </div>
                <div className="col-8">
                    <Link to="/staff/mrs-madhu-diwakar"><h5 className="mb-0">Mrs. Madhu Diwakar</h5></Link>
                    <p className=" fw-semibold">Human Resource</p>
                    <p className="mb-0 text-start">
                    Madhu has over 8 years of experience in the domain of Human Resources. She holds Masters Degree from Sikkim Manipal University. Before joining Brighter Mind, she has...
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 protfolio-bottom py-5">
        <div className="container">
          <div className="row gy-3">
            <div className="col-lg-6">
              <h5 className="mb-0 text-white">
                Want to know Brighter Mind Investment Philosophy?
              </h5>
            </div>
            <div className="col-lg-6 text-lg-end">
              <Link to="/contact" className="lets-connect py-2 px-4">
                Lets Connect <MdKeyboardArrowRight className="fs-5" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default OurTeam;
