import React from 'react'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const GallerySkeleton = () => {
  return (
    <>
     <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                    <div style={{backgroundColor: "#fff", margin: "8px 5px"}}>
                    <Skeleton width={350} height={220} />
                    
                    </div>
                    <div style={{backgroundColor: "#fff", margin: "8px 5px"}}>
                    <Skeleton width={350} height={220} />
                    
                    </div>
                    <div style={{backgroundColor: "#fff", margin: "8px 5px"}}>
                    <Skeleton width={350} height={220} />
                  
                    </div>
                    <div style={{backgroundColor: "#fff", margin: "8px 5px"}}>
                    <Skeleton width={350} height={220} />
                   
                    </div>
                    <div style={{backgroundColor: "#fff", margin: "8px 5px"}}>
                    <Skeleton width={350} height={220} />
                  
                    </div>
                    <div style={{backgroundColor: "#fff", margin: "8px 5px"}}>
                    <Skeleton width={350} height={220} />
                    
                    </div>
                   
                   
                  </div>
    </>
  )
}

export default GallerySkeleton