import React, { useEffect, useState } from "react";
import "./Style/portfoliostrategy.css";
import { Feedback } from "./Feedback";
import SideBar from "../Components/SideBar/SideBar";
import TextLinkExample from "../Components/NavBar/NavBar";
import { useContext } from "react";
import { SidebarContext } from "../../context/SidebarContext";
import LogoutCard from "../Components/Card/LogoutCard";
import Footer from "../Components/Footer/Footer";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useGetDataQuery } from "../../redux/API/dashboardApi";
import { calculateCurretValue, formatIndianNumber } from "../../Constant/utils";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);
export default function PortfolioStrategy() {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.validateEmail?._id;
  const { data } = useGetDataQuery(userId);
  const [chartData, setChartData] = useState(null);
  const [curretValue, setCurretValue] = useState(null);

  const formatValue = (value) => {
    if (value >= 10000000) {
      return (value / 10000000).toFixed(2) + " Cr.";
    } else if (value >= 100000) {
      return (value / 100000).toFixed(2) + " L";
    } else {
      return value.toFixed(2);
    }
  };
  const formatValue2 = (value) => {
    if (value >= 10000000) {
      return (value / 10000000).toFixed(0) + " Cr.";
    } else if (value >= 100000) {
      return (value / 100000).toFixed(0) + " L";
    } else {
      return value.toFixed(0);
    }
  };

  const yearsArray = [5, 10, 15, 20, 25];
  const percentagesArray = [0.16, 0.2, 0.24];

  useEffect(() => {
    if (data) {
      const calculatedValue = calculateCurretValue(data);
      setCurretValue(calculatedValue);
      const values = yearsArray.flatMap((year) =>
        percentagesArray.map(
          (percentage) => calculatedValue * Math.pow(1 + percentage, year)
        )
      );

      const updatedChartData = {
        labels: yearsArray,
        datasets: percentagesArray.map((percentage, index) => ({
          label: `${(percentage * 100).toFixed(2)}%`,
          data: yearsArray.map(
            (year) => calculatedValue * Math.pow(1 + percentage, year)
          ),
          borderColor: `rgba(${index * 80}, ${99 - index * 30}, ${
            index * 70
          }, 0.8)`,
          fill: false,
          tension: 0.1,
        })),
      };

      setChartData(updatedChartData);
    }
  }, [data]);

  const allData = chartData?.datasets.flatMap((dataset) => dataset.data);
  const minValue = allData ? Math.min(0, ...allData) : 0;

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "(In Years)",
        },
      },
      y: {
        title: {
          display: true,
          text: "(In Rs)",
        },
        beginAtZero: true,
        min: minValue,
        ticks: {
          callback: function (value) {
            return formatValue2(value);
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return formatValue2(context.raw);
          },
        },
      },
    },
  };

  return (
    <>
      <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className=" PortfolioStrategy mx-2">
            <div className="UpperBtn my-4 PortfolioStrategy1">
              <button className="download-btn mt-2">
                Download Investment Report
              </button>
            </div>
            <div className="card mx-2 PortfolioStrategy2">
              <h4 className="mx-2 my-2">Portfolio Strategy</h4>
              <h4
                className="mx-2 my-2"
                style={{
                  backgroundColor: "#9DC913",
                  borderRadius: "4px",
                  color: "white",
                  padding: "5px 10px",
                }}
              >
                {" "}
                E-50 Strategy{" "}
              </h4>
            </div>
            <div className="card mx-2 PortfolioStrategy3 my-4 py-2">
              <h5>About Strategy</h5>
              <div className="d-flex justify-content-center">
                <div className="strategy-img">
                  <img
                    src={require("../Components/photos/portfoliostrategyimg.png")}
                    alt=""
                  />
                </div>
                <p>...</p>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <MDBTable
                    hover
                    striped>
                    <MDBTableHead
                      dark
                    >
                      <tr>
                        <th scope="col" className="text-center">Years</th>
                        <th scope="col" className="text-center">16%</th>
                        <th scope="col" className="text-center">20%</th>
                        <th scope="col" className="text-center">24%</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {yearsArray.map((years, index) => (
                        <tr key={index}>
                          <td className="text-center" >{years}</td>
                          {percentagesArray.map((percentage, innerIndex) => (
                            <td className="text-center" key={innerIndex}>
                              {curretValue &&
                                formatValue(
                                  curretValue * Math.pow(1 + percentage, years)
                                )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </MDBTableBody>
                  </MDBTable>
                </div>
                <div className="col-lg-6">
                  {chartData && <Line data={chartData} options={options} />}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <Feedback />
      </div>
      {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  );
}
