import React, { useState } from 'react'
import './Style/support.css'
import { Business, PhoneAndroid } from '@mui/icons-material'
import { FloatingLabel, Form} from 'react-bootstrap'
import { Feedback } from './Feedback';
import SideBar from '../Components/SideBar/SideBar';
import TextLinkExample from '../Components/NavBar/NavBar';
import { useContext } from 'react';
import { SidebarContext } from '../../context/SidebarContext';
import LogoutCard from '../Components/Card/LogoutCard';
import Footer from '../Components/Footer/Footer';

const Support = () => {
  const { isSiderbaropen } = useContext(SidebarContext) 
  const [showLogout, setShowLogout] = useState(false)
  return (
    <>
    <div className="App">
    <SideBar />
    <div className={!isSiderbaropen ? 'pages' : 'pages-change'}>
    <TextLinkExample
   setShowLogout={setShowLogout}
    />
    <div className=' Support p-2'>
      <div className='support1 mx-4'>
        <div className='supportHeadImage my-2 mx-4 py-2 px-2'>
          <h2>Support</h2>
          <img src={require("../Components/photos/Animation - 1697612946436.gif")} alt='' /> 
        </div>
        <div className='support1card'>
          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <h3 className="card-title my-4"><i className="fa-solid fa-phone-volume fa-shake mx-3" style={{ color: " #ffffff" }}></i>Contact</h3>
                <div className="card-body">
                  <p className="card-text" ><PhoneAndroid />    9810203944</p>
                  <p className="card-text" ><PhoneAndroid />    9810203944</p>
                  {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <h3 className="card-title my-4"><i className="fa-solid fa-location-dot fa-flip mx-3" style={{ color: " #ffffff" }}></i>Address</h3>
                <div className="card-body">
                  <p className="card-text"> <Business /> ABC, Tower -4, Greater Noida, Noida, UP </p>
                  {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='support2card '>
          <div className='support2cardHead'>
           <span> Get In Touch</span> 
            <img src={require("../Components/photos/Animation - 1697786461745.gif")} alt=''/>
          </div>
          <div className='support2cardform my-4'>
            <div className='row'> 
            <div className='col'> 
            <span><i className="fa-solid fa-user"></i> Name</span>
            <FloatingLabel controlId="floatingInput" label="Name" className="mb-3" >
              <Form.Control type="name" placeholder="Enter your Name" />
            </FloatingLabel>
            </div>
            <div className='col'> 
            <span><i className="fa-solid fa-phone"></i> Phone</span>
            <FloatingLabel controlId="floatingInput" label="Phone" className="mb-3" >
              <Form.Control type="number" placeholder="+91-----" />
            </FloatingLabel>
            </div>
            </div>
            <span><i className="fa-solid fa-envelope"></i> Email</span>
            <FloatingLabel controlId="floatingInput" label="Email addres" className="mb-3" >
              <Form.Control type="email" placeholder="name@example.com" />
            </FloatingLabel>
            <span><i className="fa-solid fa-comment-dots"></i> Message</span>
            <FloatingLabel controlId="floatingTextarea2" label="Message" className="mb-3">
              <Form.Control
                as="textarea"
                placeholder="Leave a comment here"
                style={{ height: '100px' }}
              />
            </FloatingLabel>
            <button type="submit" className="btn my-2"> <i className="fa-solid fa-paper-plane"></i> Send</button>
          </div>
        </div>

      </div>
    </div>
    <Footer/>
    </div>
      <Feedback/>
      </div>
       {
        showLogout && (
          <LogoutCard
          setShowLogout={setShowLogout}
          />
        )
      }
      </>
  )
}

export default Support