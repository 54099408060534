import React, { useEffect, useState } from 'react'
import './Style/profiledetail.css'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Feedback } from './Feedback';
import SideBar from '../Components/SideBar/SideBar';
import TextLinkExample from '../Components/NavBar/NavBar';
import { useContext } from 'react';
import { SidebarContext } from '../../context/SidebarContext';
import {  useQualitativeQuery } from '../../redux/API/portfolioApi';
import LogoutCard from '../Components/Card/LogoutCard';
import { Link } from 'react-router-dom';
import { FaFilePdf } from "react-icons/fa6";
import Footer from '../Components/Footer/Footer';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Qualitative = () => {
    const { isSiderbaropen } = useContext(SidebarContext) 
    const [portfolioList, setPortfolioList] = useState([])
    const [showLogout, setShowLogout] = useState(false)
    const qualitativeAnalysis = useQualitativeQuery()
    
    useEffect(() => {
        if(qualitativeAnalysis?.isSuccess && !qualitativeAnalysis.isError && !qualitativeAnalysis.isLoading){
            setPortfolioList(qualitativeAnalysis?.data?.data)
        }
        else{ 
            // console.log("Something went wrong");
        }
    },[qualitativeAnalysis])


  return (
    <>
     <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? 'pages' : 'pages-change'}>
        <TextLinkExample
   setShowLogout={setShowLogout}
    />
        <div className='ProfileDetail' style={{minHeight: "100vh"}}>
            <div className='portfolio-detail-table my-5 col-11 flex-column align-items-lg-start  ' style={{ margin: "auto",}}>
            <p className='mb-0'>{portfolioList.length} Record(s) Found</p>
            <TableContainer className='mt-3' component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow >
                                <StyledTableCell className='qualitative-table-head'>Stocks</StyledTableCell>
                                {
                                     portfolioList?.map((res) => {
                                        return (
                                            <>
                                             {
                                            res?.qualitiveanalyses?.map((j, index) => {
                                                return (
                                                    <>
                                                     <StyledTableCell className='qualitative-table-head'>Title {index + 1}</StyledTableCell>
                                                    </>
                                                )
                                            })
                                        }
                                            </>
                                        )
                                     })
                                }
                                <StyledTableCell className='qualitative-table-head'>Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                portfolioList?.map((res, index) => {
                                    return (
                                        <StyledTableRow>
                                            {
                                                res?.stocklist.map((i) => {
                                                    return (
                                                        <>
                                        <StyledTableCell  className='qualitative-table'><strong>{i?.name}</strong></StyledTableCell>
                                        {
                                            res?.qualitiveanalyses?.map((j) => {
                                                return (
                                                    <>
                                                     <StyledTableCell  className='qualitative-table'><strong className='mb-1'>{j?.title}</strong>
                                                     <p className='mb-0'>{j?.description}</p>
                                                     </StyledTableCell>
                                                    </>
                                                )
                                            }) 
                                        }
                                       
                                        <StyledTableCell  className='qualitative-table view-btn'><Link><FaFilePdf /></Link></StyledTableCell>
                                                        </>
                                                    )
                                                })
                                            }
                                       
                                    </StyledTableRow>
                               
                                    )
                                })
                            }
                               
                        </TableBody>
                    </Table>
                       
                </TableContainer>
            </div>

        </div>
        <Footer/>
        </div>
        <Feedback/>
      </div>
      {
        showLogout && (
          <LogoutCard
          setShowLogout={setShowLogout}
          />
        )
      }
    </>
  )
}

export default Qualitative