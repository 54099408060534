import React, { useEffect, useState }  from 'react'
import './SideBarScript'
import './sidebar.css'
import { Link, NavLink } from 'react-router-dom'
import DashboardIcon from '@mui/icons-material/Dashboard';
import BadgeIcon from '@mui/icons-material/Badge';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PreviewIcon from '@mui/icons-material/Preview';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { SupportAgent } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { useContext } from 'react';
import { SidebarContext } from '../../../context/SidebarContext';
import { FaList, FaUser } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io"; 
import { useProfileInfoQuery } from '../../../redux/API/portfolioApi';
import { imageUrl } from '../../../Constant/Matcher';

const SideBar = () => {
const { toggleSidebar } = useContext(SidebarContext)
const { isSiderbaropen } = useContext(SidebarContext) 
const [showMenu, setShowMenu] = useState(false)
const [profileData, setProfileData] = useState('')
const getProfile = useProfileInfoQuery()
const handleMemu = () => {
  if(showMenu === false){
    setShowMenu(true)
  }
  else{
    setShowMenu(false)
  }
}

useEffect(() => {
  if(getProfile.isSuccess && !getProfile.isError && !getProfile.isLoading){
   setProfileData(getProfile?.data?.user)
  }
  else{
  //  console.log("Something went wrong");
  }
}, [getProfile])

  return (
    <div className='SideBar'>
      <div className={!isSiderbaropen ? `sidebar` : `sidebar open`}>
        <div className="logo-details">
          <div className="logo_name">
            <p className=' text-white mb-1 text-center'>{profileData?.userid?.name}</p>
            <div className='profile-image'>
              <img src={`${imageUrl}${profileData?.profileimg}` || require('../photos/png-transparent-computer-icons-user-user-icon-thumbnail.png')} alt='profile-img' />
            </div>
          </div>
          {
            isSiderbaropen ? (

            <i className='bx bx-menu' id="btn" onClick={() => toggleSidebar()}><MenuIcon /></i>
            )
            : 
            (

              <i className="fa-solid fa-xmark" id="btn" onClick={() => toggleSidebar()}></i>
            )
          }
        </div>
        <ul className="nav-list px-0">
          <li>
            <NavLink to="/client/">
              <i> <DashboardIcon /></i>
              <span className="links_name">Dashboard</span>
            </NavLink>
            <span className="tooltip">Dashboard</span>
          </li>
          <li onClick={handleMemu}>
            <Link>
              <i><FaList /></i>
              <span className="links_name">Portfolio <IoIosArrowDown className='ms-5'/></span>
            </Link>
            <span className="tooltip">Portfolio Detail</span>
            {
              showMenu && (
            
            <ul className={!isSiderbaropen ? "nav-sub-items" : "nav-sub-items-change"} >
              <li className="sub-item">
              <NavLink to="/client/portfoliodetail">
              <i>< BadgeIcon /></i>
              <span className="links_name">Portfolio Detail</span>
            </NavLink>
              </li>
              <li className="sub-item">
              <NavLink to="/client/qualitative-analysis">
              <i>< BadgeIcon /></i>
              <span className="links_name">Qualitative Analysis</span>
            </NavLink>
              </li>
            </ul>
                
                )
              }
          </li>
          <li>
            <NavLink to="/client/portfoliostrategy">
              <i><ManageAccountsIcon /></i>
              <span className="links_name">Portfolio Strategy</span>
            </NavLink>
            <span className="tooltip">Portfolio Strategy</span>
          </li>
          <li>
            <NavLink to="/client/managersdesk">
              <i><BackupTableIcon /></i>
              <span className="links_name">Manager's Desk</span>
            </NavLink>
            <span className="tooltip">Manager's Desk</span>
          </li>
          <li>
            <NavLink to="/client/transactionupload">
              <i><UploadFileIcon /></i>
              <span className="links_name">Transaction Upload</span>
            </NavLink>
            <span className="tooltip">Transaction Upload</span>
          </li>
          <li>
            <NavLink to="/client/portfolioreview">
              <i><PreviewIcon /></i>
              <span className="links_name">Portfolio Review</span>
            </NavLink>
            <span className="tooltip">Portfolio Review</span>
          </li>
          <li>
            <NavLink to="/client/billsinvoice">
              <i><DescriptionIcon /></i>
              <span className="links_name">Bills & Invoice</span>
            </NavLink>
            <span className="tooltip">Bills & Invoice</span>
          </li>
          <li>
            <NavLink to="/client/referus">
              <i ><GroupAddIcon /></i>
              <span className="links_name">Refer US</span>
            </NavLink>
            <span className="tooltip">Refer US</span>
          </li>
          <li>
            <NavLink to="/client/support">
              <i ><SupportAgent /></i>
              <span className="links_name">Support</span>
            </NavLink>
            <span className="tooltip">Support</span>
          </li>
          <li>
            <NavLink to="/client/profile">
              <i ><FaUser/></i>
              <span className="links_name">Profile</span>
            </NavLink>
            <span className="tooltip">Support</span>
          </li>
          {/* <li>
            <NavLink to="/feedback">
              <i ><Announcement /></i>
              <span className="links_name">Feedback</span>
            </NavLink>
            <span className="tooltip">Feedback</span>
          </li> */}
          {/* <li className="profile">
            <div className="profile-details">
              <img src="profile.jpg" alt="profileImg" />
              <div className="name_job">
                <div className="name">Prem Shahi</div>
                <div className="job">Web designer</div>
              </div>
            </div>
            <i className='bx bx-log-out' id="log_out" ></i>
          </li> */}
        </ul>
      </div>
      {/* <section className="home-section">
        <div className="text">Dashboard</div>
      </section> */}



    </div>


  )
}


export default SideBar


