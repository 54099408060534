import React, { useEffect } from "react";
import Navbar from "../components/partial/Navbar";
import Footer from "../components/partial/Footer";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Blog from "../components/Home/Blog";
const AlternateInvestement = () => {
  useEffect(() => {
    AOS.init({
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false,
    });
  }, []);

  return (
    <>
      <Navbar />
      <section className="portfolio-section alternate-investement">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 text-white">
              <div className="mt-5">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link> <MdKeyboardArrowRight />
                    </li>
                    <li
                      className="breadcrumb-item text-white "
                      aria-current="page"
                    >
                      Alternate Investment Fund
                    </li>
                  </ol>
                </nav>
              </div>
              <div data-aos="fade-up">
             <h2 className="fw-bold mt-5 pt-lg-5 text-uppercase">Alternate Investment Fund</h2>
              <p>
               Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio officiis voluptates ratione vero, sint aperiam porro hic ea? Voluptate, sint.
              </p>
             </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 container">
        <h2 className="heading text-center " data-aos="fade-up">
          Delivering Value with Alternate Investment Fund
        </h2>
        <div className="row mt-3">
          <div className="col-12">
            <p className=" text-dark-emphasis " data-aos="fade-up">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eveniet
              dolore maiores a error aliquam perspiciatis obcaecati tempore
              neque, expedita mollitia repellendus perferendis exercitationem!
              Repellat, impedit consequuntur? Lorem ipsum dolor sit amet
              consectetur, adipisicing elit. Numquam atque fugiat, beatae rerum
              optio molestias esse eius recusandae eum quam consequatur
              accusantium praesentium magni minima nostrum iste? Expedita?
            </p>
          </div>
        </div>
      </section>
      <section className="mt-3 py-5 bg-body-secondary">
        <div className="container">
          <h2 className="heading text-center " >
            Brighter Mind Alternate Investment Fund Benefits
          </h2>
          <div className="row mt-3">
            <di className="col-12">
              <div className="row gy-4 justify-content-center">
                <div className="col-lg-3 col-md-4 col-6 text-center fw-medium">
                  <i class="fa-solid fa-circle-check fs-5 me-2" style={{color: "#80B214"}}></i>Lorem, ipsum.
                </div>
                <div className="col-lg-3 col-md-4 col-6 text-center fw-medium">
                <i class="fa-solid fa-circle-check fs-5 me-2" style={{color: "#80B214"}}></i>Lorem, ipsum.
                 
                </div>
                <div className="col-lg-3 col-md-4 col-6 text-center fw-medium">
                <i class="fa-solid fa-circle-check fs-5 me-2" style={{color: "#80B214"}}></i>Lorem, ipsum.
                 
                </div>
                <div className="col-lg-3 col-md-4 col-6 text-center fw-medium">
                <i class="fa-solid fa-circle-check fs-5 me-2" style={{color: "#80B214"}}></i>Lorem, ipsum.
                  
                </div>
                <div className="col-lg-3 col-md-4 col-6 text-center fw-medium">
                <i class="fa-solid fa-circle-check fs-5 me-2" style={{color: "#80B214"}}></i>Lorem, ipsum.
                 
                </div>
                <div className="col-lg-3 col-md-4 col-6 text-center fw-medium">
                <i class="fa-solid fa-circle-check fs-5 me-2" style={{color: "#80B214"}}></i>Lorem, ipsum.
                 
                </div>
                <div className="col-lg-3 col-md-4 col-6 text-center fw-medium">
                <i class="fa-solid fa-circle-check fs-5 me-2" style={{color: "#80B214"}}></i>Lorem, ipsum.
                </div>
                <div className="col-lg-3 col-md-4 col-6 text-center fw-medium">
                <i class="fa-solid fa-circle-check fs-5 me-2" style={{color: "#80B214"}}></i>Lorem, ipsum.
                </div>
              </div>
            </di>
          </div>
        </div>
      </section>
      <section className="mt-5 container overflow-hidden">
        <h2 className="heading text-center ">Brighter Mind Secret Advantage Fund</h2>
        <div className="row gy-3 mt-3">
          <div className="col-md-6" data-aos="fade-right">
            <img
              src="https://plus.unsplash.com/premium_photo-1681487769650-a0c3fbaed85a?q=80&w=1910&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
              className="w-100 h-100 object-fit-cover"
            />
          </div>
          <div className="col-md-6">
            <p className=" text-dark-emphasis " data-aos="fade-left">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis
              corporis totam molestiae aspernatur quam earum consectetur
              sapiente placeat dicta fugiat eum, vel sit debitis quis minus
              aliquid explicabo quaerat voluptatem perferendis esse fugit maxime
              illum dolorum voluptatum. Porro iusto quos dicta delectus minima
              molestias? Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Dolor inventore atque nostrum nesciunt ad assumenda, facere
              hic saepe nulla incidunt.
            </p>
          </div>
          <div className="col-md-6 order-4 order-md-3">
            <p className=" text-dark-emphasis " data-aos="fade-right">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis
              corporis totam molestiae aspernatur quam earum consectetur
              sapiente placeat dicta fugiat eum, vel sit debitis quis minus
              aliquid explicabo quaerat voluptatem perferendis esse fugit maxime
              illum dolorum voluptatum. Porro iusto quos dicta delectus minima
              molestias? Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Dolor inventore atque nostrum nesciunt ad assumenda, facere
              hic saepe nulla incidunt.
            </p>
          </div>
          <div className="col-md-6 order-3 order-md-4" data-aos="fade-left">
            <img
              src="https://plus.unsplash.com/premium_photo-1681487769650-a0c3fbaed85a?q=80&w=1910&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
              className="w-100 h-100 object-fit-cover"
            />
          </div>
        </div>
      </section>
      <section className="mt-5 py-4 bg-body-secondary">
        <div className="container">
          <h2 className="heading text-center ">Investment Philosophy</h2>
          <div className="row gy-5 mt-1 justify-content-center">
            <div className="col-lg-3">
            <div class="investement-card shadow-md" data-aos="flip-up">
               <div className=" position-absolute card-title-container py-2">
               <h6 class="card-title text-center text-white">Lorem, ipsum dolor.</h6>
               </div>
                <p class="small-desc">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Quaerat veritatis nobid
                </p>
              </div>
            </div>
            <div className="col-lg-3">
            <div class="investement-card investement-card-2 shadow-md" data-aos="flip-up">
                <div className=" position-absolute card-title-container ">
                <h6 class="card-title text-center text-white py-2">Lorem, ipsum dolor.</h6>
                </div>
                <p class="small-desc">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Quaerat veritatis nobid
                </p>
              </div>
            </div>
            <div className="col-lg-3">
            <div class="investement-card shadow-md" data-aos="flip-up">
               <div className=" position-absolute card-title-container ">
               <h6 class="card-title text-center text-white py-2">Lorem, ipsum dolor.</h6>
               </div>
                <p class="small-desc">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Quaerat veritatis nobid
                </p>
              </div>
            </div>
            <div className="col-lg-3">
            <div class="investement-card investement-card-2 shadow-md" data-aos="flip-up">
               <div className=" position-absolute card-title-container ">
               <h6 class="card-title text-center text-white py-2">Lorem, ipsum dolor.</h6>
               </div>
                <p class="small-desc">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Quaerat veritatis nobid
                </p>
              </div>
            </div>
            <div className="col-lg-3">
            <div class="investement-card shadow-md" data-aos="flip-up">
               <div className=" position-absolute card-title-container ">
               <h6 class="card-title text-center text-white py-2">Lorem, ipsum dolor.</h6>
               </div>
                <p class="small-desc">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Quaerat veritatis nobid
                </p>
              </div>
            </div>
            <div className="col-lg-3">
            <div class="investement-card investement-card-2 shadow-md" data-aos="flip-up">
               <div className=" position-absolute card-title-container ">
               <h6 class="card-title text-center text-white py-2">Lorem, ipsum dolor.</h6>
               </div>
                <p class="small-desc">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Quaerat veritatis nobid
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div class="investement-card shadow-md" data-aos="flip-up">
               <div className=" position-absolute card-title-container ">
               <h6 class="card-title text-center text-white py-2">Lorem, ipsum dolor.</h6>
               </div>
                <p class="small-desc">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Quaerat veritatis nobid
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 container">
        <h2 className="heading text-center ">FAQ</h2>
        <div className="row gy-3 mt-3">
            <div className="col-12">
            <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button shadow-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
       Lorem ipsum dolor sit amet.
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button shadow-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      Lorem ipsum dolor sit amet.
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button shadow-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      Lorem ipsum dolor sit amet.
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
    </div>
  </div>
</div>
            </div>
        </div>
        </section>
      <Blog/>
      <Footer />
    </>
  );
};

export default AlternateInvestement;
