import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const BarChart = ({chartData}) => {

      const options = {
        responsive: true,
        indexAxis: 'y', 
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Top 10 Industries by Current Portfolio Value',
          },
        },
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              callback: function(value) {
                return value.toFixed(2) + '%';
              },
              stepSize: 10,
          },
        },
        },
      };

  return (
    <>
    <div style={{height: "400px"}}>
    <Bar data={chartData} options={options} style={{width: "100%", height: "400px"}}/>
    </div>
    </>
  )
}

export default BarChart